import {useRecoilLoadable} from 'shared/hooks/useRecoilLoadable';
import {subscriptionReadSelector, subscriptionSelector} from '../state/subscription';

export const useSubscriptionLoadable = () => {
    const {data, loading, error} = useRecoilLoadable({
        state: subscriptionSelector,
        loadable: subscriptionReadSelector,
    });
    return {
        subscription: data?.subscription,
        loading,
        error,
    };
};
