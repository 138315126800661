import React from 'react';
import './PlansAndFeaturesTable.scss';

const PlansAndFeaturesTable = () => {
    const pricingTableData = [
        {
            title: 'Create legal documents',
            description:
                'Our Document Builder creates legally binding documents in minutes. Simply answer a questionnaire to generate a document tailored to your needs.',
            free: false,
            basic: true,
            pro: true,
            // enterprise: true,
        },
        {
            title: 'Access our library of 500+ documents',
            description:
                'Our builder dynamically creates documents based on your responses. securely storing your details, our software makes completing future documents even easier by pre-filling your information.',
            free: false,
            basic: false,
            pro: true,
            // enterprise: true,
        },
        {
            title: 'Smart drafting technology',
            description:
                'Our Document Builder creates legally binding documents in minutes. Simply answer a questionnaire to generate a document tailored to your needs.',
            free: false,
            basic: false,
            pro: true,
            // enterprise: true,
        },
        {
            title: 'Create legal documents',
            description:
                'Our Document Builder creates legally binding documents in minutes. Simply answer a questionnaire to generate a document tailored to your needs.',
            free: false,
            basic: true,
            pro: true,
            // enterprise: true,
        },
        {
            title: 'Create legal documents',
            description:
                'Our Document Builder creates legally binding documents in minutes. Simply answer a questionnaire to generate a document tailored to your needs.',
            free: false,
            basic: true,
            pro: true,
            // enterprise: true,
        },
    ];

    return (
        <div className="pricing-table-wrapper">
            <div className="pricing-table">
                <div className="features-header">Features</div>
                <div className="plan-header">Free</div>
                <div className="plan-header">Basic</div>
                <div className="plan-header">Pro</div>
                {/* <div className="plan-header">Enterprise</div> */}

                {pricingTableData.map((item, index) => (
                    <React.Fragment key={index}>
                        <div className="feature-title">{item.title}</div>
                        <div className="feature-description">{item.description}</div>
                        <div className="plan-value">
                            {item.free ? (
                                <span className="check">
                                    {' '}
                                    <img src={'/assets/plans/right.svg'} alt="img" />
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                        <div className="plan-value">
                            {item.basic ? (
                                <span className="check">
                                    {' '}
                                    <img src={'/assets/plans/right.svg'} alt="img" />
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                        <div className="plan-value">
                            {item.pro ? (
                                <span className="check">
                                    <img src={'/assets/plans/right.svg'} alt="img" />
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                        {/* <div className="plan-value">
                            {item.enterprise ? (
                                <span className="check">
                                    {' '}
                                    <img src={'/assets/plans/right.svg'} alt="img" />
                                </span>
                            ) : (
                                '-'
                            )}
                        </div> */}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default PlansAndFeaturesTable;
