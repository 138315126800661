import {atom, selector} from 'recoil';

import {readSubscription} from '../api';
import {IBillingSubscription} from '../models';
import {guaranteedCurrentUserIdSelector} from 'shared/auth/state/current-user-id';
import {guardRecoilDefaultValue} from 'shared/utils/recoil';

interface ISubscriptionState {
    subscription: IBillingSubscription;
    resetVersion: number;
}

export const subscriptionAtom = atom<ISubscriptionState | undefined>({
    key: 'subscriptionAtom',
    default: undefined,
});

export const subscriptionResetAtom = atom<number>({
    key: 'subscriptionResetAtom',
    default: 1,
});

export const subscriptionSelector = selector<ISubscriptionState | undefined>({
    key: 'subscriptionSelector',
    get: async ({get}) => {
        const atomValue = get(subscriptionAtom);
        const resetVersion = get(subscriptionResetAtom);
        if (atomValue && atomValue.resetVersion === resetVersion) {
            return atomValue;
        }
        return undefined;
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(subscriptionAtom, newValue);
    },
});

export const subscriptionReadSelector = selector<ISubscriptionState>({
    key: 'subscriptionReadSelector',
    get: async ({get}) => {
        const currentValue = get(subscriptionSelector);
        if (currentValue) {
            return currentValue;
        }

        const userID = get(guaranteedCurrentUserIdSelector);
        const resetVersion = get(subscriptionResetAtom);
        const subscription = await readSubscription(userID);
        return {
            subscription,
            resetVersion,
        };
    },
});
